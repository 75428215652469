<template>
  <div>
    <nuxt />
    <CreditInfoWidget v-show="showWidgetCredit" />
    <Freshdesk />
  </div>
</template>

<script>
import CreditInfoWidget from '~/components/common/CreditInfoWidget.vue'
import Freshdesk from '~/components/common/Freshdesk.vue'

export default {
  components: {
    CreditInfoWidget,
    Freshdesk
  },
  data() {
    return {
      showWidgetCredit: false
    }
  },
  mounted() {
    this.checkCookieSetting()
    if (
      !(
        this.$route.name === 'profile' &&
        this.$route.query.page_active === 'credit_unpaid_bill'
      )
    ) {
      this.showWidgetCredit = true
    }
    this.$bus.$on('changeTabActive', (tabData) => {
      if (
        !(
          this.$route.name === 'profile' &&
          tabData.tabName === 'credit_unpaid_bill'
        )
      ) {
        this.showWidgetCredit = true
      } else {
        this.showWidgetCredit = false
      }
    })
  },
  head: {
    script: [
      {
        src: '/js/klaro-config-' + process.env.NODE_ENV + '.js',

        defer: true
      },
      {
        src: '/js/klaro.js',
        defer: true,
        'data-config': 'klaroConfig',
        'data-style-prefix': 'klaro'
      }
    ]
  }
}
</script>

<style lang="scss">
html {
  font-family: $font-family;
  font-size: 16px;
  word-spacing: 1px;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  box-sizing: border-box;
}

body {
  font-family: $font-family;
}

*,
*:before,
*:after {
  box-sizing: border-box;
  margin: 0;
}

.button--green {
  display: inline-block;
  border-radius: 4px;
  border: 1px solid #3b8070;
  color: #3b8070;
  text-decoration: none;
  padding: 10px 30px;
}

.button--green:hover {
  color: #fff;
  background-color: #3b8070;
}

.button--grey {
  display: inline-block;
  border-radius: 4px;
  border: 1px solid #35495e;
  color: #35495e;
  text-decoration: none;
  padding: 10px 30px;
  margin-left: 15px;
}

.button--grey:hover {
  color: #fff;
  background-color: #35495e;
}

.desktop-headline-1 {
  font-size: $desktop-headline-1;
}

.button-small-square {
  height: 44px;
  padding: 10px;
  border: 1px solid $color-medium-gray;
  vertical-align: middle;
  text-align: center;
  display: inline-table;
  width: 168px;
  cursor: pointer;
}

.button-facebook-login {
  background-image: url('../static/images/icon-facebook.png');
  background-repeat: no-repeat;
  background-position: 24px 50%;
  left: 24px;
  cursor: pointer;
  border: none;
  border-radius: 2px;
  background-color: #4167b2;
  text-align: center;
  color: #ffffff;
  display: block;
  width: 100%;
  padding: 10px 0px;
}

.button-google-plus-login {
  background-image: url('../static/images/icon_google_plus.png');
  background-repeat: no-repeat;
  background-position: 24px 50%;
  left: 24px;

  cursor: pointer;
  border: none;
  border-radius: 2px;
  background-color: #dd5144;
  text-align: center;
  color: #ffffff;
  display: block;
  width: 100%;
  padding: 10px 0px;
}

.button-small-redius {
  width: 38px;
  height: 38px;
  border: 1px solid $color-medium-gray;
  box-sizing: border-box;
  text-align: center;
  padding: 5px;
  vertical-align: middle;
  border-radius: 50%;
  font-size: 16px;
  display: inline-block;
  cursor: pointer;
}

.border {
  border: 1px solid $color-medium-gray;
  box-sizing: border-box;
}

.button-small-redius.arrow-next::before {
  content: '>';
  color: $color-black;
}

.button-small-redius.arrow-previous::before {
  content: '<';
  color: $color-black;
}

.arrow-next::before {
  content: '>';
  font-size: 16px;
  color: $color-blue;
}

.arrow-previous::before {
  content: '<';
  font-size: 16px;
  color: $color-blue;
}
</style>
